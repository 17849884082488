var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"550","persistent":""},model:{value:(_vm.isCreatePromotionModalVisible),callback:function ($$v) {_vm.isCreatePromotionModalVisible=$$v},expression:"isCreatePromotionModalVisible"}},[[_c('v-card',[_c('v-toolbar',[_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"width":"100%"}},[_c('h3',{staticClass:"font-weight-regular text-uppercase"},[_vm._v(" "+_vm._s(this.$t("promotions.submit"))+" ")]),_c('v-spacer'),_c('div',[_c('v-icon',{on:{"click":_vm.toggleCreatePromotionModal}},[_vm._v("mdi-close")])],1)],1)]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-form',{ref:"form",model:{value:(_vm.is_valid),callback:function ($$v) {_vm.is_valid=$$v},expression:"is_valid"}},[_c('date-time-picker-input',{attrs:{"label":this.$t('start_date'),"rules":[
              (value) => {
                return (
                  new Date(_vm.startDate).getTime() <
                    new Date(_vm.endDate).getTime() ||
                  'Start date should be inferior to end date'
                );
              },
            ]},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('date-time-picker-input',{attrs:{"label":this.$t('end_date'),"rules":[
              (value) => {
                return (
                  new Date(_vm.endDate).getTime() >
                    new Date(_vm.startDate).getTime() ||
                  'End date should be superior to the start date'
                );
              },
            ]},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('p',[_vm._v(" "+_vm._s(this.$t("promotions.create_remark"))+" ")]),_c('div',{staticClass:"d-flex justify-end"},[_c('div',[_c('v-btn',{staticClass:"me-2",attrs:{"text":""},on:{"click":_vm.toggleCreatePromotionModal}},[_vm._v(_vm._s(this.$t("cancel")))]),_c('v-btn',{attrs:{"color":"primary","disabled":!this.startDate || !this.endDate},on:{"click":_vm.createPromotion}},[_vm._v(_vm._s(this.$t("create")))])],1)])],1)],1)],1),_c('confirm-schedule-modal',{ref:"confirm"})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }