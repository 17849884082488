<template>
  <v-dialog
    v-model="isCreatePromotionModalVisible"
    max-width="550"
    persistent
  >
    <template>
      <v-card>
        <v-toolbar>
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%"
          >
            <h3 class="font-weight-regular text-uppercase">
              {{ this.$t("promotions.submit") }}
            </h3>
            <v-spacer></v-spacer>
            <div>
              <v-icon @click="toggleCreatePromotionModal">mdi-close</v-icon>
            </div>
          </div>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="is_valid"
          >
            <date-time-picker-input
              :label="this.$t('start_date')"
              v-model="startDate"
              :rules="[
                (value) => {
                  return (
                    new Date(startDate).getTime() <
                      new Date(endDate).getTime() ||
                    'Start date should be inferior to end date'
                  );
                },
              ]"
            ></date-time-picker-input>
            <date-time-picker-input
              :label="this.$t('end_date')"
              v-model="endDate"
              :rules="[
                (value) => {
                  return (
                    new Date(endDate).getTime() >
                      new Date(startDate).getTime() ||
                    'End date should be superior to the start date'
                  );
                },
              ]"
            ></date-time-picker-input>
            <p>
              {{ this.$t("promotions.create_remark") }}
            </p>
            <div class="d-flex justify-end">
              <div>
                <v-btn
                  text
                  class="me-2"
                  @click="toggleCreatePromotionModal"
                  >{{ this.$t("cancel") }}</v-btn
                >
                <v-btn
                  color="primary"
                  @click="createPromotion"
                  :disabled="!this.startDate || !this.endDate"
                  >{{ this.$t("create") }}</v-btn
                >
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
      <confirm-schedule-modal ref="confirm" />
    </template>
  </v-dialog>
</template>
<script>
import promotionsService from "@/store/services/promotions-service";
import { Promotion } from "../promotion.class";
export default {
  props: {
    isCreatePromotionModalVisible: Boolean,
    toggleCreatePromotionModal: Function,
    promotion: Promotion,
    promotionDetails: Promotion,
  },
  components: {
    DateTimePickerInput: () => import("../modals/DateTimePickerInput.vue"),
    ConfirmScheduleModal: () => import("../modals/ConfirmScheduleModal.vue"),
  },
  data() {
    return {
      startDate: this.promotionDetails.start_date,
      endDate: this.promotionDetails.end_date,
      is_valid: false,
    };
  },
  watch: {
    endDate() {
      this.$refs.form.validate();
    },
  },
  methods: {
    createPromotion() {
      const start_date = this.startDate;
      const end_date = this.endDate;
      if (this.$refs.form.validate()) {
        promotionsService
          .schedule(this.promotion.id, { start_date, end_date, check: true })
          .then((data) => {
            if (data?.promotions?.length) {
              this.$refs.confirm.open(data.promotions).then((resp) => {
                if (resp) {
                  promotionsService
                    .schedule(this.promotion.id, {
                      start_date,
                      end_date,
                      check: false,
                    })
                    .then(() => {
                      this.$router.push("/promotions");
                    });
                }
              });
            } else {
              this.$router.push("/promotions");
            }
          });
      }
    },
  },
};
</script>
<style>
.v-dialog {
  overflow-y: visible;
}
.v-dialog__content {
  align-items: flex-start;
}
</style>